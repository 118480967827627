import { abortNavigation, defineNuxtRouteMiddleware, useNuxtApp } from 'nuxt/app';
import { storeToRefs } from 'pinia';
import type { RouteLocationNormalized } from 'vue-router';

import { showConfirm } from '@/composables/useDialog';
import { PRODUCT_FEAT_DISABLED } from '@/constants/error.const';
import { disableCondition1 } from '@/constants/page.const';
import { PRODUCT_HOME_PAGE_NAME, PRODUCT_LAUNCH_SETTING_PAGE_URL } from '@/constants/url.const';
import useProductStore from '@/stores/product.store';
import { isDirectAccess, redirectTo } from '@/utils/common.util';

export default defineNuxtRouteMiddleware(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    const { name: toName } = to;
    const { name: fromName } = from;
    const productStore = useProductStore();
    const { currentProductDetailType, platform } = storeToRefs(productStore);

    const nuxtApp = useNuxtApp();
    const t = (nuxtApp.$i18n as any)?.t;

    let disabled = false;
    const disableConditions = [...disableCondition1];
    let disableCount = 0;
    for (const pf of platform.value) {
      for (const condition of disableConditions) {
        if (
          pf === condition.platform &&
            condition.detailType === currentProductDetailType.value
        ) {
          disableCount++;
        }
      }
    }
    disabled = platform.value.length === disableCount;

    if (disabled) {
      if (isDirectAccess(toName, fromName)) {
        return await redirectTo(PRODUCT_HOME_PAGE_NAME, {
          toType: 'name',
          query: { error: PRODUCT_FEAT_DISABLED }
        });
      }
      const result = await showConfirm({
        content: t('studio.prj_prod.this_prod.home_in_app_pur_cond_type_pc_set_n'),
        confirmLabel: t('studio.prj_prod.this_prod.home_in_app_pur_cond_type_pc_set_n_to_go'),
        cancelLabel: t('studio.common.popup_case_close_btn'),
        cancelVariant: 'outline'
      });
      if (result) {
        return await redirectTo(PRODUCT_LAUNCH_SETTING_PAGE_URL);
      }
      return abortNavigation();
    }
  }
);
